@tailwind base;
@tailwind components;
@tailwind utilities;

/* =================================== SCROLL BAR STYLING =================================== */
/* Scrollbar modification */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #161618;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 0.375rem;
}

/* =================================== NAV SIDEBAR STYLING =================================== */
.navListItemActive {
  @apply flex items-center  p-3 rounded-lg text-start  transition-all text-accent cursor-default outline-none font-DMsans font-bold duration-300 ease-linear;
}

.navListItemInactive {
  @apply flex items-center  p-3 rounded-lg text-start  transition-all text-white  outline-none cursor-pointer opacity-70 font-DMsans hover:text-accent duration-300 ease-linear;
}

/* .navListItemInactive {
  @apply flex items-center  p-3 rounded-lg text-start  transition-all text-white hover:bg-blue-gray-50 hover:bg-opacity-80 hover:text-blue-gray-900 outline-none cursor-pointer opacity-80 font-DMsans;
} */

/* =================================== BOX/TEXT SHADOWS =================================== */
.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 3px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.boxShadow1 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
}

.textShadow {
  text-shadow: 0px 0px 30px rgb(0 0 0 / 50%);
}

/* =================================== INPUT WHEEL =================================== */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* =================================== TOGGLE SWITCH =================================== */
/* The switch - the box around the slider */
.switch {
  font-size: 13px;
  position: relative;
  display: inline-block;
  width: 3.8em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #161618;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #66ff8e;
  border: 1px solid #000d0f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #66ff8e;
}

input:checked + .slider:before {
  transform: translateX(1.7em);
  background-color: #000d0f;
}

/* =================================== TOAST STYLING =================================== */
.toastBodySuccess {
  @apply font-DMsans text-green-600 tab:text-xl;
}
.toastBodyError {
  @apply font-DMsans text-red-500 tab:text-xl;
}
.toastBodyPending {
  @apply font-DMsans text-yellow-500 tab:text-xl;
}

/* =================================== LOADER =================================== */
.loader {
  width: 16px;
  height: 16px;
  border: 2px solid #c7c4c4;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
